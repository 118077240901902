import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { INTL_LOCALES, IntlModule } from 'angular-ecmascript-intl';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import superjson from 'superjson';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { PagesModule } from './pages/pages.module';
import { KratosAuthenticationService } from './services/authentication/kratos.service';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { registerLocaleData } from '@angular/common';
import {
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { createTranslateLoader } from './services/translation/http-loader';
import { MessageParser } from './services/translation/parser';

import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
} from '@ngxs/storage-plugin';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { IntercomService } from './services/intercom/intercom.service';
import { FCMService } from './services/notifications/fcm.service';
import { ObservabilityService } from './services/observability/observability.service';
import { PermissionModule } from './services/permission/permission.module';
import { ToastService } from './services/toast/toast.service';
import { FeaturesStateModule } from './store/features/features.state.module';
import { MiscState } from './store/misc/misc.state';
import { NotificationsState } from './store/notifications/notifications.state';
import { NotificationsStateModule } from './store/notifications/notifications.state.module';
import { ServicesStateModule } from './store/services/services.state.module';
import { TrackingStateModule } from './store/tracking/tracking.state.module';
import { UISettingsState } from './store/ui-settings/ui-settings.state';
import { UserState } from './store/user/user.state';
import { UserStateModule } from './store/user/user.state.module';

registerLocaleData(localeDE);
registerLocaleData(localeEN);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([MiscState, UISettingsState], {
      selectorOptions: {
        suppressErrors: true,
      },
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      keys: [
        {
          key: UserState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: UISettingsState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: NotificationsState,
          engine: LOCAL_STORAGE_ENGINE,
        },
      ],
      serialize: obj => {
        return superjson.stringify(obj);
      },
      deserialize: obj => {
        return superjson.parse(obj);
      },
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      latency: 0,
    }),
    RouterModule,
    PagesModule,
    GraphQLModule,
    IntlModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      parser: {
        provide: TranslateParser,
        useClass: MessageParser,
        deps: [TranslateDefaultParser],
      },
    }),
    UserStateModule,
    FeaturesStateModule,
    ServicesStateModule,
    TrackingStateModule,
    ToastModule,
    PermissionModule,
    FontAwesomeModule,
    NgxStripeModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: environment.gtm.id,
      gtm_preview: environment.gtm.previewEnv,
      gtm_auth: environment.gtm.auth,
    }),
    NotificationsStateModule,
  ],
  providers: [
    KratosAuthenticationService,
    TranslateDefaultParser,
    ToastService,
    MessageService,
    CookieService,
    ObservabilityService,
    IntercomService,
    FCMService,
    // Sentry error tracking
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: INTL_LOCALES,
      useValue: ['en-US'],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() =>
      initializeApp({
        projectId: 'incidite-687a5',
        appId: '1:221325142302:web:5e1d1970d032d9bfeac10f',
        storageBucket: 'incidite-687a5.firebasestorage.app',
        apiKey: 'AIzaSyBjD8VycHJLLnrHsKbzqQA2nTFKtuZvA04',
        authDomain: 'incidite-687a5.firebaseapp.com',
        messagingSenderId: '221325142302',
      })
    ),
    provideMessaging(() => getMessaging()),
  ],
})
export class AppModule {}
