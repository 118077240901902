import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommunicationStateModule } from '../communication/communication.state.module';
import { NotificationsState } from './notifications.state';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forFeature([NotificationsState]),
    CommunicationStateModule,
  ],
  providers: [],
  bootstrap: [],
  exports: [],
})
export class NotificationsStateModule {}
