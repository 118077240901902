import { Registry } from './registry.js';
export class ClassRegistry extends Registry {
  constructor() {
    super(c => c.name);
    this.classToAllowedProps = new Map();
  }
  register(value, options) {
    if (typeof options === 'object') {
      if (options.allowProps) {
        this.classToAllowedProps.set(value, options.allowProps);
      }
      super.register(value, options.identifier);
    } else {
      super.register(value, options);
    }
  }
  getAllowedProps(value) {
    return this.classToAllowedProps.get(value);
  }
}
