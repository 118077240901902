import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { FCMService } from 'src/app/services/notifications/fcm.service';
import { CommunicationService } from './communication.service';
import { CommunicationState } from './communication.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([CommunicationState])],
  providers: [CommunicationService, FCMService],
  bootstrap: [],
  exports: [],
})
export class CommunicationStateModule {}
