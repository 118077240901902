import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { IntlModule } from 'angular-ecmascript-intl';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { VTruncatedContainerComponent } from 'src/app/core/components/ui-kit/truncated-container/truncated-container.component';
import { Incident } from 'src/app/models/incident';
import { UserState } from 'src/app/store/user/user.state';
import { IncidentPrioritySwitchComponent } from '../../../incidents/components/incident-priority-switch/incident-priority-switch.component';
import { IncidentStateSwitchComponent } from '../../../incidents/components/incident-state-switch/incident-state-switch.component';
import { IncidentStateComponent } from '../../../incidents/components/incident-state/incident-state.component';

@Component({
  selector: 'app-active-incident',
  templateUrl: './active-incident.component.html',
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    ComponentsModule,
    CommonModule,
    IntlModule,
    IncidentStateComponent,
    IncidentStateSwitchComponent,
    IncidentPrioritySwitchComponent,
    VTruncatedContainerComponent,
  ],
})
export class ActiveIncidentComponent {
  private store = inject(Store);
  @Input({ required: true }) incident!: Incident;

  canUpdate = this.store.selectSignal(
    UserState.checkPermissionRole(['incidents.manager'])
  );
}
