import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  mapGQLResponse,
  toIdentifiableEntities,
} from 'src/app/helpers/graphql';
import {
  IncidentSchema,
  IncidentSchemaTaskTemplateLink,
  IncidentSchemaVersion,
} from 'src/app/models/incident-schema';
import { TaskTemplateSpec } from 'src/app/models/task';
import { IdentifiableEntities } from 'src/app/store/common/list-entity';

export const LIST_INCIDENT_SCHEMAS_QUERY = gql`
  query ListIncidentSchemas($organizationId: ID!) {
    incidentSchemas(organizationId: $organizationId) {
      schemas {
        id
        name
        type
        currentVersion {
          id
          version
        }
      }
    }
  }
`;

export const GET_INCIDENT_SCHEMA_QUERY = gql`
  query GetIncidentSchemas($organizationId: ID!, $schemaId: ID!) {
    incidentSchema(organizationId: $organizationId, schemaId: $schemaId) {
      id
      name
      type
      currentVersion {
        id
        version
        states {
          id
          name
          order
          color
          icon
          isDefault
          isResolved
        }
        priorities {
          id
          name
          order
          color
          icon
        }
        impacts {
          id
          name
          order
          color
          icon
        }
      }
    }
  }
`;

export const GET_INCIDENT_SCHEMA_TASK_TEMPLATES_QUERY = gql`
  query GetIncidentSchemaTaskTemplates($organizationId: ID!, $schemaId: ID!) {
    incidentSchema(organizationId: $organizationId, schemaId: $schemaId) {
      currentVersion {
        taskTemplates {
          linkId
          template {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_INCIDENT_SCHEMA_VERSION_QUERY = gql`
  query GetIncidentSchemaVersion($organizationId: ID!, $schameVersionId: ID!) {
    incidentSchemaVersion(
      organizationId: $organizationId
      schemaVersionId: $schameVersionId
    ) {
      id
      version
      states {
        id
        name
        order
        color
        icon
        isDefault
        isResolved
      }
      priorities {
        id
        name
        order
        color
        icon
      }
    }
  }
`;

const CREATE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION = gql`
  mutation CreateIncidentSchemaTaskTemplate(
    $organizationId: ID!
    $schemaVersionId: ID!
    $taskTemplateSpec: IncidentSchemaTaskTemplateSpec!
  ) {
    createIncidentSchemaTaskTemplate(
      organizationId: $organizationId
      schemaVersionId: $schemaVersionId
      taskTemplateSpec: $taskTemplateSpec
    ) {
      linkId
      template {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION = gql`
  mutation UpdateIncidentSchemaTaskTemplate(
    $organizationId: ID!
    $linkId: ID!
    $taskTemplateSpec: IncidentSchemaTaskTemplateSpec!
  ) {
    updateIncidentSchemaTaskTemplate(
      organizationId: $organizationId
      linkId: $linkId
      taskTemplateSpec: $taskTemplateSpec
    ) {
      linkId
      template {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION = gql`
  mutation DeleteIncidentSchemaTaskTemplate(
    $organizationId: ID!
    $linkId: ID!
  ) {
    deleteIncidentSchemaTaskTemplate(
      organizationId: $organizationId
      linkId: $linkId
    )
  }
`;

@Injectable()
export class IncidentSchemasService {
  private apollo = inject(Apollo);

  listIncidentSchemas(
    organizationId: string
  ): Observable<IdentifiableEntities<IncidentSchema>> {
    return this.apollo
      .query<{ incidentSchemas: { schemas: IncidentSchema[] } }>({
        query: LIST_INCIDENT_SCHEMAS_QUERY,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          return toIdentifiableEntities(
            data.data.incidentSchemas.schemas.map(schema => {
              return {
                ...schema,
                createdAt: new Date(
                  Date.parse(schema.createdAt as unknown as string)
                ),
              };
            }),
            'id'
          );
        })
      );
  }

  getIncidentSchema(
    organizationId: string,
    schemaId: string
  ): Observable<IncidentSchema> {
    return this.apollo
      .query<{ incidentSchema: IncidentSchema }>({
        query: GET_INCIDENT_SCHEMA_QUERY,
        variables: {
          organizationId: organizationId,
          schemaId: schemaId,
        },
      })
      .pipe(
        map(data => {
          return data.data.incidentSchema;
        })
      );
  }

  getIncidentSchemaVersion(
    organizationId: string,
    schemaVersionId: string
  ): Observable<IncidentSchemaVersion> {
    return this.apollo
      .query<{ incidentSchemaVersion: IncidentSchemaVersion }>({
        query: GET_INCIDENT_SCHEMA_VERSION_QUERY,
        variables: {
          organizationId: organizationId,
          schameVersionId: schemaVersionId,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            incidentSchemaVersion: IncidentSchemaVersion;
          }>(data, 'incidentSchemaVersion');
        })
      );
  }

  getIncidentSchemaTaskTemplates(
    organizationId: string,
    schemaId: string
  ): Observable<IdentifiableEntities<IncidentSchemaTaskTemplateLink>> {
    return this.apollo
      .query<{
        incidentSchema: {
          currentVersion: { taskTemplates: IncidentSchemaTaskTemplateLink[] };
        };
      }>({
        query: GET_INCIDENT_SCHEMA_TASK_TEMPLATES_QUERY,
        variables: {
          organizationId: organizationId,
          schemaId: schemaId,
        },
      })
      .pipe(
        map(data => {
          return toIdentifiableEntities(
            data.data.incidentSchema.currentVersion.taskTemplates,
            'linkId'
          );
        })
      );
  }

  createIncidentSchemaTaskTemplate(
    organizationId: string,
    schemaVersionId: string,
    taskTemplateSpec: TaskTemplateSpec
  ): Observable<IncidentSchemaTaskTemplateLink> {
    return this.apollo
      .mutate<{
        createIncidentSchemaTaskTemplate: IncidentSchemaTaskTemplateLink;
      }>({
        mutation: CREATE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION,
        variables: {
          organizationId: organizationId,
          schemaVersionId: schemaVersionId,
          taskTemplateSpec: taskTemplateSpec,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createIncidentSchemaTaskTemplate;
        })
      );
  }

  updateIncidentSchemaTaskTemplate(
    organizationId: string,
    linkId: string,
    taskTemplateSpec: TaskTemplateSpec
  ): Observable<IncidentSchemaTaskTemplateLink> {
    return this.apollo
      .mutate<{
        updateIncidentSchemaTaskTemplate: IncidentSchemaTaskTemplateLink;
      }>({
        mutation: UPDATE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION,
        variables: {
          organizationId: organizationId,
          linkId: linkId,
          taskTemplateSpec: taskTemplateSpec,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentSchemaTaskTemplate;
        })
      );
  }

  deleteIncidentSchemaTaskTemplate(
    organizationId: string,
    linkId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{
        deleteIncidentSchemaTaskTemplate: boolean;
      }>({
        mutation: DELETE_INCIDENT_SCHEMA_TASK_TEMPLATE_MUTATION,
        variables: {
          organizationId: organizationId,
          linkId: linkId,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            deleteIncidentSchemaTaskTemplate: boolean;
          }>(data, 'deleteIncidentSchemaTaskTemplate');
        })
      );
  }
}
