<div class="min-h-screen flex relative lg:static bg-gray-900 text-primary">
  <app-sidebar></app-sidebar>
  <div class="flex flex-col overflow-hidden w-full max-h-screen">
    <header>
      <app-topbar></app-topbar>
    </header>
    <div class="mt-3 px-3 overflow-y-auto flex-1">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!--
<v-sidebar [visible]="true">
  Whup
</v-sidebar>
-->