import { inject, Injectable, OnDestroy } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { VKitDialogService } from 'src/app/core/components/ui-kit/dialogs/dialog.service';
import { CommunicationProviderType } from 'src/app/models/communication-provider';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ListEntitiesToIterable } from '../common/list-entity';
import {
  CreateWebPushRecipientEndpointAction,
  LoadRecipientEndpointsForOrganizationMemberAction,
} from '../communication/communication.actions';
import { CommunicationState } from '../communication/communication.state';
import { UserState } from '../user/user.state';
import { SetIgnoreAskForPushUntilAction } from './notifications.actions';

const CHECK_NOTIFICATIONS_INTERVAL = 1000 * 60 * 60; // 1 hour
const ASK_FOR_PUSH_INTERVAL = 1000 * 60 * 60 * 24 * 7; // 1 week
// const CHECK_NOTIFICATIONS_INTERVAL = 1000; // 1 hour
// const ASK_FOR_PUSH_INTERVAL = 1000 * 10;

export interface NotificationsStateModel {
  lastChecked?: Date;
  ignoreAskForPushUntil?: Date;
  hasPushConfigured?: boolean;
}

@State<NotificationsStateModel>({
  name: 'notifications',
  children: [],
  defaults: {},
})
@Injectable()
export class NotificationsState implements NgxsOnInit, OnDestroy {
  private store = inject(Store);
  private ds = inject(VKitDialogService);
  private toast = inject(ToastService);

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngxsOnInit(ctx: StateContext<NotificationsStateModel>) {
    this.store
      .select(UserState.isLoggedIn)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.initializeNotifications(ctx);
        }
      });
  }

  private initializeNotifications(ctx: StateContext<NotificationsStateModel>) {
    const lastChecked = ctx.getState().lastChecked;

    if (
      !lastChecked ||
      lastChecked.getTime() <
        new Date().getTime() - CHECK_NOTIFICATIONS_INTERVAL
    ) {
      ctx
        .dispatch(new LoadRecipientEndpointsForOrganizationMemberAction())
        .subscribe(() => {
          const endpoints = this.store.selectSnapshot(
            CommunicationState.orgMemberRecipientEndpoints
          );

          const hasPushConfigured = ListEntitiesToIterable(
            endpoints
          ).entities.some(
            e => e.data?.providerType === CommunicationProviderType.FCM
          );

          ctx.patchState({
            lastChecked: new Date(),
            hasPushConfigured: hasPushConfigured,
          });

          // If push notifications are not configured, ask the user to enable them
          if (!hasPushConfigured) {
            this.askForPushNotifications(ctx);
          }
        });
    }
  }

  private askForPushNotifications(ctx: StateContext<NotificationsStateModel>) {
    const ignoreAskForPushUntil = ctx.getState().ignoreAskForPushUntil;

    if (
      ignoreAskForPushUntil &&
      ignoreAskForPushUntil.getTime() > new Date().getTime()
    ) {
      return;
    }

    this.ds
      .confirm({
        header: 'Enable Push Notifications?',
        description:
          'Push notifications will inform you about incidents, tasks and other important events in real-time.',
        confirmIcon: 'pi pi-bell',
        confirmText: 'Enable Push Notifications',
        cancelText: 'Not now',
        variant: 'primary',
      })
      .then(ok => {
        if (ok) {
          ctx.dispatch(new CreateWebPushRecipientEndpointAction());
        } else {
          const ignoreUntil = new Date(
            new Date().getTime() + ASK_FOR_PUSH_INTERVAL
          );
          ctx.dispatch(new SetIgnoreAskForPushUntilAction(ignoreUntil));
        }
      });
  }

  @Action(SetIgnoreAskForPushUntilAction)
  setIgnoreAskForPushUntil(
    ctx: StateContext<NotificationsStateModel>,
    action: SetIgnoreAskForPushUntilAction
  ) {
    ctx.patchState({
      ignoreAskForPushUntil: action.ignoreUntil,
    });
  }
}
