<article
  class="bg-card hover:bg-card-hover active:bg-card-active p-3 rounded-lg cursor-pointer"
  [routerLink]="['/incidents/', incident.id]">
  <header class="flex items-center justify-between">
    <div class="flex items-center overflow-hidden">
      @if (canUpdate()) {
        <app-incident-priority-switch
          [incident]="incident!"
          size="sm"
          context="global"></app-incident-priority-switch>
      } @else {
        <app-priority [priority]="incident.priority"></app-priority>
      }
      <h4 class="p-0 m-0 ml-2 v-text-primary truncate font-medium">
        {{ incident.title }}
      </h4>
    </div>
    <div>
      @if (canUpdate()) {
        <app-incident-state-switch
          [incident]="incident!"
          size="sm"
          context="global"></app-incident-state-switch>
      } @else {
        <app-incident-state [state]="incident.state"></app-incident-state>
      }
    </div>
  </header>
  <footer class="flex pt-2 mt-3 justify-between overflow-hidden gap-4">
    <div class="flex flex-1 overflow-hidden flex-col">
      <header class="text-md text-gray-100 pb-2 font-medium">
        {{ 'components.activeIncidents.affectedServices' | translate }}
      </header>

      <div class="flex gap-2">
        @if (incident.affectedServices?.length === 0) {
          <span class="text-gray-200">{{
            'components.activeIncidents.noAffectedServices' | translate
          }}</span>
        } @else {
          <v-truncated-container [showMoreTemplate]="moreTemplate">
            @for (
              service of incident.affectedServices;
              track service.service.id
            ) {
              <ng-template #item>
                <div class="my-auto flex gap-2">
                  <div
                    class="bg-gray-700 px-2 py-1 rounded-lg text-sm font-medium text-nowrap">
                    {{ service.service.name }}
                  </div>
                </div>
              </ng-template>
            } 
          </v-truncated-container>
        }

        <ng-template #moreTemplate let-count="count">
          <span class="custom-more-indicator text-nowrap">
            <div
              class="text-gray-400 bg-gray-700/40 px-2 py-1 rounded-lg text-sm font-medium text-nowrap">
              +{{ count }} more
            </div>
          </span>
        </ng-template>
      </div>
    </div>
    <div class="flex gap-4 flex-shrink-0">
      <article class="flex flex-1 flex-col">
        <header class="text-md text-gray-100 pb-2 font-medium">
          {{ 'components.activeIncidents.activeSince' | translate }}
        </header>
        <div class="my-auto">
          <div class="text-sm font-medium v-text-primary">
            {{ incident.createdAt | intlRelativeTime: { numeric: 'auto' } }}
          </div>
        </div>
      </article>
      <article class="flex flex-col">
        <header class="text-md text-gray-100 pb-2 font-medium">
          {{ 'components.activeIncidents.statuspages' | translate }}
        </header>
        <div class="flex gap-1">
          @for (sp of incident.statuspageLinks; track sp.statuspage.id) {
            <app-chip
              [link]="'https://' + sp.statuspage.domain"
              icon="pi-link"
              [label]="sp.statuspage.name"></app-chip>
          } @empty {
            <span class="text-gray-200">{{
              'components.activeIncidents.noStatuspagesLinked' | translate
            }}</span>
          }
        </div>
      </article>
    </div>
  </footer>
</article>
