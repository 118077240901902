import { find } from './util.js';
export class CustomTransformerRegistry {
  constructor() {
    this.transfomers = {};
  }
  register(transformer) {
    this.transfomers[transformer.name] = transformer;
  }
  findApplicable(v) {
    return find(this.transfomers, transformer => transformer.isApplicable(v));
  }
  findByName(name) {
    return this.transfomers[name];
  }
}
