import { inject, Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { map } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Task, TaskStatus } from 'src/app/models/task';
import {
  IteratableListEntities,
  ListEntities,
  ListEntitiesToIterable,
  loadEntities,
  NewListEntities,
  updateListEntity,
} from '../common/list-entity';
import { IncidentsService } from '../incidents/incidents.service';
import { UserState } from '../user/user.state';
import {
  AssignTaskAction,
  LoadTasksForIncidentAction,
  UpdateTaskStatusAction,
} from './tasks.actions';
import { TasksService } from './tasks.service';

export interface TasksStateModel {
  tasks: ListEntities<Task>;
}

@State<TasksStateModel>({
  name: 'tasks',
  defaults: {
    tasks: NewListEntities(),
  },
})
@Injectable()
export class TasksState {
  store = inject(Store);

  private incidentService = inject(IncidentsService);
  private tasksService = inject(TasksService);

  @Selector()
  static tasks(state: TasksStateModel): IteratableListEntities<Task> {
    return ListEntitiesToIterable(state.tasks);
  }

  static tasksInStatus(status: TaskStatus[]) {
    return createSelector([TasksState], (state: TasksStateModel) => {
      if (!state.tasks) return [];

      return Object.values(state.tasks.entities).filter(task =>
        status.includes(task.data!.status)
      );
    });
  }

  static tasksByIds(ids: string[]) {
    return createSelector([TasksState], (state: TasksStateModel) => {
      if (!state.tasks) return [];

      return ids
        .map(id => state.tasks.entities[id])
        .filter(task => task !== undefined);
    });
  }

  @Action(LoadTasksForIncidentAction)
  loadTasksForIncident(
    ctx: StateContext<TasksStateModel>,
    { incidentId }: LoadTasksForIncidentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'tasks',
      this.incidentService.loadIncidentTasks(organizationID, incidentId).pipe(
        map(tasks => {
          return toIdentifiableEntities(
            Object.values(tasks).map(task => {
              return task.data!.task;
            }),
            'id'
          );
        })
      )
    );
  }

  @Action(UpdateTaskStatusAction)
  updateTaskStatus(
    ctx: StateContext<TasksStateModel>,
    { taskId, status }: UpdateTaskStatusAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return updateListEntity(
      ctx,
      'tasks',
      taskId,
      this.tasksService.updateTaskStatus(organizationID, taskId, status),
      true
    );
  }

  @Action(AssignTaskAction)
  assignTask(
    ctx: StateContext<TasksStateModel>,
    { taskId, assigneeId }: AssignTaskAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return updateListEntity(
      ctx,
      'tasks',
      taskId,
      this.tasksService.assignTask(organizationID, taskId, assigneeId),
      true
    );
  }
}
