import { RecipientEndpointSpec } from 'src/app/models/notifications';

export class CreateDiscordProviderProfileAction {
  static readonly type = '[Communication] Create discord provider profile';
  constructor(public guildId: string) {}
}

export class CreateSlackProviderProfileAction {
  static readonly type = '[Communication] Create slack provider profile';
  constructor(public oauthCode: string) {}
}

export class CreateTeamsProviderProfileAction {
  static readonly type = '[Communication] Create teams provider profile';
  constructor(
    public tenantId: string,
    public teamId: string
  ) {}
}

export class LinkProviderFailedAction {
  static readonly type = '[Communication] Failed to link provider profile';
  constructor(public error: string) {}
}

export class RemoveProviderProfileAction {
  static readonly type = '[Communication] Remove provider profile';
  constructor(public providerProfileId: string) {}
}

export class LoadCommunicationProviderProfilesAction {
  static readonly type = '[Communication] Load communication provider profiles';
}

export class LoadProviderProfileChannelsAction {
  static readonly type = '[Communication] Load provider profile channels';
  constructor(public providerProfileId: string) {}
}

export class LoadRecipientEndpointsForOrganizationAction {
  static readonly type =
    '[Communication] Load recipient endpoints for organization';
}

export class LoadRecipientEndpointsForOrganizationMemberAction {
  static readonly type =
    '[Communication] Load recipient endpoints for organization member';
}

export class CreateRecipientEndpointAction {
  static readonly type = '[Communication] Create recipient endpoint';
  constructor(
    public providerId: string,
    public spec: RecipientEndpointSpec
  ) {}
}

export class CreateOrgMemberRecipientEndpointAction {
  static readonly type = '[Communication] Create org member recipient endpoint';
  constructor(
    public providerId: string,
    public spec: RecipientEndpointSpec
  ) {}
}

export class ForwardToDiscordAction {
  static readonly type = '[Communication] Redirecting to discord';
}

export class ForwardToSlackAction {
  static readonly type = '[Communication] Redirecting to slack';
}

export class RemoveOrgMemberRecipientEndpointAction {
  static readonly type = '[Communication] Remove org member recipient endpoint';
  constructor(public endpointId: string) {}
}

export class CreateWebPushRecipientEndpointAction {
  static readonly type = '[Communication] Create web push recipient endpoint';
}
