import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  CommunicationProviderProfile,
  CommunicationProviderType,
} from 'src/app/models/communication-provider';
import {
  ProviderChannelMetadata,
  RecipientEndpoint,
  RecipientEndpointSpec,
} from 'src/app/models/notifications';

const CREATE_PROVIDER_PROFILE_MUTATION = gql`
  mutation CreateProviderProfile(
    $organizationId: ID!
    $spec: ProviderProfileSpec!
  ) {
    createProviderProfile(
      organizationId: $organizationId
      providerProfileSpec: $spec
    ) {
      id
    }
  }
`;

const REMOVE_PROVIDER_PROFILE_MUTATION = gql`
  mutation RemoveProviderProfile(
    $organizationId: ID!
    $providerProfileId: ID!
  ) {
    removeProviderProfile(
      organizationId: $organizationId
      providerProfileId: $providerProfileId
    )
  }
`;

const LIST_PROVIDER_PROFILES_QUERY = gql`
  query ListProviderProfiles($organizationId: ID!) {
    providerProfiles(organizationId: $organizationId) {
      id
      name
      type
      configuration {
        ... on SlackAppProviderProfileConfig {
          teamName
        }
        ... on DiscordAppProviderProfileConfig {
          guildName
        }
        ... on TeamsProviderProfileConfig {
          teamName
        }
      }
    }
  }
`;

const LIST_PROVIDER_PROFILE_CHANNELS_QUERY = gql`
  query ListProviderProfileChannels(
    $organizationId: ID!
    $providerProfileId: ID!
  ) {
    providerProfileChannels(
      organizationId: $organizationId
      providerProfileId: $providerProfileId
    ) {
      id
      name
    }
  }
`;

const CREATE_ORG_RECIPIENT_ENDPOINT_MUTATION = gql`
  mutation CreateOrgRecipientEndpoint(
    $organizationId: ID!
    $providerProfileId: ID!
    $spec: RecipientEndpointSpec!
  ) {
    createOrgRecipientEndpoint(
      organizationId: $organizationId
      providerProfileId: $providerProfileId
      spec: $spec
    ) {
      id
      providerType
      providerProfileId
      config {
        ... on ProviderChannelMetadata {
          id
          name
        }
        ... on ProviderAddressMetadata {
          address
        }
      }
    }
  }
`;

const CREATE_ORG_MEMBER_RECIPIENT_ENDPOINT_MUTATION = gql`
  mutation CreateOrgMemberRecipientEndpoint(
    $organizationId: ID!
    $memberId: String!
    $providerProfileId: ID!
    $spec: RecipientEndpointSpec!
  ) {
    createOrgMemberRecipientEndpoint(
      organizationId: $organizationId
      memberId: $memberId
      providerProfileId: $providerProfileId
      spec: $spec
    ) {
      id
      providerType
      providerProfileId
      deletable
      config {
        ... on ProviderChannelMetadata {
          id
          name
        }
        ... on ProviderAddressMetadata {
          address
        }
      }
    }
  }
`;

const LOAD_ORG_RECIPIENT_ENDPOINTS = gql`
  query LoadOrgRecipientEndpoints($organizationId: ID!) {
    orgRecipientEndpoints(organizationId: $organizationId) {
      id
      providerType
      providerProfileId
      config {
        ... on ProviderChannelMetadata {
          id
          name
        }
      }
    }
  }
`;

const LOAD_ORG_MEMBER_RECIPIENT_ENDPOINTS = gql`
  query LoadOrgMemberRecipientEndpoints(
    $organizationId: ID!
    $memberId: String!
  ) {
    orgMemberRecipientEndpoints(
      organizationId: $organizationId
      memberId: $memberId
    ) {
      id
      providerType
      providerProfileId
      deletable
      config {
        ... on ProviderChannelMetadata {
          id
          name
        }
        ... on ProviderAddressMetadata {
          __typename
          address
        }
      }
    }
  }
`;

const REMOVE_ORG_MEMBER_RECIPIENT_ENDPOINT_MUTATION = gql`
  mutation RemoveOrgMemberRecipientEndpoint(
    $organizationId: ID!
    $memberId: String!
    $recipientEndpointId: String!
  ) {
    removeOrgMemberRecipientEndpoint(
      organizationId: $organizationId
      memberId: $memberId
      recipientEndpointId: $recipientEndpointId
    )
  }
`;

type ProviderConfig =
  | {
      type: 'SLACK_APP';
      slackAppConfig: {
        code: string;
      };
    }
  | {
      type: 'DISCORD_APP';
      discordAppConfig: {
        guildId: string;
      };
    }
  | {
      type: 'TEAMS';
      teamsConfig: {
        tenantId: string;
        teamId: string;
      };
    };

@Injectable()
export class CommunicationService {
  private apollo = inject(Apollo);

  createProviderProfile(
    organizationId: string,
    spec: ProviderConfig
  ): Observable<string> {
    return this.apollo
      .mutate<{ createProviderProfile: { id: string } }>({
        mutation: CREATE_PROVIDER_PROFILE_MUTATION,
        variables: {
          organizationId: organizationId,
          spec: spec,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createProviderProfile.id;
        })
      );
  }

  removeProviderProfile(
    organizationId: string,
    providerProfileId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ removeProviderProfile: boolean }>({
        mutation: REMOVE_PROVIDER_PROFILE_MUTATION,
        variables: {
          organizationId: organizationId,
          providerProfileId: providerProfileId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.removeProviderProfile;
        })
      );
  }

  listProviderProfiles(
    organizationId: string
  ): Observable<CommunicationProviderProfile[]> {
    return this.apollo
      .query<{ providerProfiles: CommunicationProviderProfile[] }>({
        query: LIST_PROVIDER_PROFILES_QUERY,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.providerProfiles;
        })
      );
  }

  createRecipientEndpoint(
    organizationId: string,
    providerProfileId: string,
    spec: RecipientEndpointSpec
  ): Observable<RecipientEndpoint> {
    return this.apollo
      .query<{ createOrgRecipientEndpoint: RecipientEndpoint }>({
        query: CREATE_ORG_RECIPIENT_ENDPOINT_MUTATION,
        variables: {
          organizationId: organizationId,
          providerProfileId: providerProfileId,
          spec: spec,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createOrgRecipientEndpoint;
        })
      );
  }

  createOrgMemberRecipientEndpoint(
    organizationId: string,
    providerProfileId: string,
    memberId: string,
    spec: RecipientEndpointSpec
  ): Observable<RecipientEndpoint> {
    return this.apollo
      .query<{ createOrgMemberRecipientEndpoint: RecipientEndpoint }>({
        query: CREATE_ORG_MEMBER_RECIPIENT_ENDPOINT_MUTATION,
        variables: {
          organizationId: organizationId,
          providerProfileId: providerProfileId,
          memberId: memberId,
          spec: spec,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createOrgMemberRecipientEndpoint;
        })
      );
  }

  loadOrgRecipientEndpoints(
    organizationId: string
  ): Observable<RecipientEndpoint[]> {
    return this.apollo
      .query<{ orgRecipientEndpoints: RecipientEndpoint[] }>({
        query: LOAD_ORG_RECIPIENT_ENDPOINTS,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.orgRecipientEndpoints;
        })
      );
  }

  loadOrgMemberRecipientEndpoints(
    organizationId: string,
    memberId: string
  ): Observable<RecipientEndpoint[]> {
    return this.apollo
      .query<{ orgMemberRecipientEndpoints: RecipientEndpoint[] }>({
        query: LOAD_ORG_MEMBER_RECIPIENT_ENDPOINTS,
        variables: {
          organizationId: organizationId,
          memberId: memberId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.orgMemberRecipientEndpoints.map(endpoint => {
            return this.enrichRecipientEndpoint(endpoint);
          });
        })
      );
  }

  loadProviderProfileChannels(
    organizationId: string,
    providerProfileId: string
  ): Observable<ProviderChannelMetadata[]> {
    return this.apollo
      .query<{ providerProfileChannels: ProviderChannelMetadata[] }>({
        query: LIST_PROVIDER_PROFILE_CHANNELS_QUERY,
        variables: {
          organizationId: organizationId,
          providerProfileId: providerProfileId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.providerProfileChannels;
        })
      );
  }

  removeOrgMemberRecipientEndpoint(
    organizationId: string,
    memberId: string,
    recipientEndpointId: string
  ): Observable<boolean> {
    return this.apollo
      .query<{ removeOrgMemberRecipientEndpoint: boolean }>({
        query: REMOVE_ORG_MEMBER_RECIPIENT_ENDPOINT_MUTATION,
        variables: {
          organizationId: organizationId,
          memberId: memberId,
          recipientEndpointId: recipientEndpointId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.removeOrgMemberRecipientEndpoint;
        })
      );
  }

  enrichRecipientEndpoint(endpoint: RecipientEndpoint): RecipientEndpoint {
    endpoint.image = this.getProviderImage(endpoint.providerType);
    return endpoint;
  }

  getProviderImage(type: CommunicationProviderType): string | undefined {
    switch (type) {
      case CommunicationProviderType.DISCORD_APP:
        return 'assets/images/discord.svg';
      case CommunicationProviderType.SLACK_APP:
        return 'assets/images/slack.svg';
      case CommunicationProviderType.MAIL:
        return 'assets/images/email.svg';
    }
    return;
  }
}
