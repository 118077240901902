import { inject, Injectable } from '@angular/core';
import {
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from '@angular/fire/messaging';
import { Observable } from 'rxjs';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class FCMService {
  private toast = inject(ToastService);

  message$ = new Observable<MessagePayload>(sub =>
    onMessage(this.msg, msg => sub.next(msg))
  );

  constructor(private msg: Messaging) {
    this.message$.subscribe({
      next: msg => {
        if (msg.data?.['type'] === 'notification') {
          this.toast.showInfo(
            msg.data?.['title'] || 'New Notification',
            msg.data?.['body'] || ''
          );
        }
      },
      error: err => console.error('Error receiving message:', err),
    });
  }

  register() {
    return new Promise<string>((resolve, reject) => {
      Notification.requestPermission().then(
        (notificationPermissions: NotificationPermission) => {
          if (notificationPermissions === 'granted') {
            console.log('Granted');
          }
          if (notificationPermissions === 'denied') {
            console.log('Denied');
          }
        }
      );

      navigator.serviceWorker
        .register('/assets/scripts/firebase-messaging-sw.js', {
          type: 'module',
        })
        .then(serviceWorkerRegistration => {
          getToken(this.msg, {
            vapidKey: `BDvcjMgDKHeIc7qhLpM32mnNwmxgBFHgFaVfY5F2i6obrxQpfqwrhLiYJ1WH0cwnPcmy3flSnVGdQ9Nm3elULcE`,
            serviceWorkerRegistration: serviceWorkerRegistration,
          })
            .then(token => {
              resolve(token);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
