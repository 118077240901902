import { inject, Injectable, Injector } from '@angular/core';
import { isSupported } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, map, of, tap } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import {
  CommunicationProviderProfile,
  CommunicationProviderType,
} from 'src/app/models/communication-provider';
import {
  hasProviderChannelMetadata,
  ProviderChannelMetadata,
  RecipientEndpoint,
} from 'src/app/models/notifications';
import { FCMService } from 'src/app/services/notifications/fcm.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { environment } from 'src/environments/environment';
import {
  addListEntity,
  ListEntities,
  loadEntities,
  NewListEntities,
  removeListEntity,
} from '../common/list-entity';
import { UserState } from '../user/user.state';
import {
  CreateDiscordProviderProfileAction,
  CreateOrgMemberRecipientEndpointAction,
  CreateRecipientEndpointAction,
  CreateSlackProviderProfileAction,
  CreateTeamsProviderProfileAction,
  CreateWebPushRecipientEndpointAction,
  ForwardToDiscordAction,
  ForwardToSlackAction,
  LinkProviderFailedAction,
  LoadCommunicationProviderProfilesAction,
  LoadProviderProfileChannelsAction,
  LoadRecipientEndpointsForOrganizationAction,
  LoadRecipientEndpointsForOrganizationMemberAction,
  RemoveOrgMemberRecipientEndpointAction,
  RemoveProviderProfileAction,
} from './communication.actions';
import { CommunicationService } from './communication.service';

export interface CommunicationStateModel {
  providerProfiles: ListEntities<CommunicationProviderProfile>;
  orgRecipientEndpoints: ListEntities<RecipientEndpoint>;
  orgMemberRecipientEndpoints: ListEntities<RecipientEndpoint>;
  providerProfileChannels: ListEntities<ProviderChannelMetadata>;
}

@State<CommunicationStateModel>({
  name: 'communication',
  children: [],
  defaults: {
    providerProfiles: NewListEntities(),
    orgRecipientEndpoints: NewListEntities(),
    orgMemberRecipientEndpoints: NewListEntities(),
    providerProfileChannels: NewListEntities(),
  },
})
@Injectable()
export class CommunicationState {
  store = inject(Store);
  communicationService = inject(CommunicationService);
  toast = inject(ToastService);
  router = inject(Router);
  private injector = inject(Injector);

  @Selector()
  static providerProfiles(state: CommunicationStateModel) {
    return state.providerProfiles;
  }

  @Selector()
  static orgRecipientEndpoints(state: CommunicationStateModel) {
    return state.orgRecipientEndpoints;
  }

  @Selector()
  static orgMemberRecipientEndpoints(state: CommunicationStateModel) {
    return state.orgMemberRecipientEndpoints;
  }

  @Selector()
  static providerProfileChannels(state: CommunicationStateModel) {
    return state.providerProfileChannels;
  }

  @Selector()
  static unusedProviderProfileChannels(state: CommunicationStateModel) {
    const endpoints = state.orgRecipientEndpoints.entities;

    return {
      ...state.providerProfileChannels,
      entities: Object.fromEntries(
        Object.entries(state.providerProfileChannels.entities).filter(
          ([, channelEntity]) => {
            const existingEndpoint = Object.values(endpoints).find(endpoint => {
              if (hasProviderChannelMetadata(endpoint.data!)) {
                const config = endpoint.data!.config as ProviderChannelMetadata;
                return config.id === channelEntity.data?.id;
              } else {
                return false;
              }
            });

            return !existingEndpoint;
          }
        )
      ),
    };
  }

  static orgRecipientEndpointsForProviderProfileId(providerProfileId: string) {
    return createSelector([this], (state: CommunicationStateModel) => {
      return {
        ...state.orgMemberRecipientEndpoints,
        entities: Object.fromEntries(
          Object.entries(state.orgRecipientEndpoints?.entities).filter(
            ([, v]) => {
              return v.data?.providerProfileId === providerProfileId;
            }
          )
        ),
      };
    });
  }

  static getCommunicationProviderProfile(
    providerType: CommunicationProviderType
  ) {
    return createSelector([this], (state: CommunicationStateModel) => {
      return Object.values(state.providerProfiles?.entities).find(p => {
        return p.data?.type === providerType;
      });
    });
  }

  static isCommunicationProviderConfigured(
    providerType: CommunicationProviderType
  ) {
    return createSelector([this], (state: CommunicationStateModel) => {
      const provider = Object.values(state.providerProfiles?.entities).find(
        p => {
          return p.data?.type === providerType;
        }
      );
      return provider !== undefined;
    });
  }

  static orgMemberNotificationEndpoint(endpointId: string) {
    return createSelector(
      [CommunicationState],
      (state: CommunicationStateModel) => {
        return state.orgMemberRecipientEndpoints.entities
          ? state.orgMemberRecipientEndpoints.entities[endpointId]
          : false;
      }
    );
  }

  @Action(LoadCommunicationProviderProfilesAction)
  loadCommunicationProviderProfiles(
    ctx: StateContext<CommunicationStateModel>
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'providerProfiles',
      this.communicationService.listProviderProfiles(organizationID).pipe(
        map(profiles => {
          return toIdentifiableEntities(profiles, 'id');
        })
      )
    );
  }

  @Action(CreateSlackProviderProfileAction)
  createSlackProviderProfile(
    ctx: StateContext<CommunicationStateModel>,
    { oauthCode }: CreateSlackProviderProfileAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.communicationService
      .createProviderProfile(organizationID, {
        type: 'SLACK_APP',
        slackAppConfig: {
          code: oauthCode,
        },
      })
      .pipe(
        tap(() => {
          this.toast.showInfo('Success', 'Slack provider linked');
        }),
        catchError(err => {
          this.toast.showError(err);
          return of();
        })
      );
  }

  @Action(CreateTeamsProviderProfileAction)
  createTeamsProviderProfile(
    ctx: StateContext<CommunicationStateModel>,
    { tenantId, teamId }: CreateTeamsProviderProfileAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.communicationService
      .createProviderProfile(organizationID, {
        type: 'TEAMS',
        teamsConfig: {
          tenantId: tenantId,
          teamId: teamId,
        },
      })
      .pipe(
        tap(() => {
          this.toast.showInfo('Success', 'Teams provider linked');
        }),
        catchError(err => {
          this.toast.showError(err);
          return of();
        })
      );
  }

  @Action(CreateDiscordProviderProfileAction)
  createDiscordProviderProfile(
    ctx: StateContext<CommunicationStateModel>,
    { guildId }: CreateDiscordProviderProfileAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.communicationService
      .createProviderProfile(organizationID, {
        type: 'DISCORD_APP',
        discordAppConfig: {
          guildId: guildId,
        },
      })
      .pipe(
        tap(() => {
          this.toast.showInfo('Success', 'Discord provider linked');
        }),
        catchError(err => {
          this.toast.showError(err);
          return of();
        })
      );
  }

  @Action(LoadRecipientEndpointsForOrganizationAction)
  loadOrgRecipientEndpoints(ctx: StateContext<CommunicationStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'orgRecipientEndpoints',
      this.communicationService
        .loadOrgRecipientEndpoints(organizationID)
        .pipe(map(endpoints => toIdentifiableEntities(endpoints, 'id')))
    );
  }

  @Action(LoadRecipientEndpointsForOrganizationMemberAction)
  loadOrgMemberRecipientEndpoints(ctx: StateContext<CommunicationStateModel>) {
    const membership = this.store.selectSnapshot(UserState.membership);

    if (!membership) throw new Error('No membership set');

    return loadEntities(
      ctx,
      'orgMemberRecipientEndpoints',
      this.communicationService
        .loadOrgMemberRecipientEndpoints(
          membership.organizationId,
          membership.id
        )
        .pipe(map(endpoints => toIdentifiableEntities(endpoints, 'id')))
    );
  }

  @Action(LoadProviderProfileChannelsAction)
  loadProviderProfileChannels(
    ctx: StateContext<CommunicationStateModel>,
    { providerProfileId }: LoadProviderProfileChannelsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'providerProfileChannels',
      this.communicationService
        .loadProviderProfileChannels(organizationID, providerProfileId)
        .pipe(map(channels => toIdentifiableEntities(channels, 'id'))),
      {
        clear: true,
      }
    );
  }

  @Action(RemoveProviderProfileAction)
  removeProviderProfile(
    ctx: StateContext<CommunicationStateModel>,
    { providerProfileId }: RemoveProviderProfileAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'providerProfiles',
      providerProfileId,

      (profile: CommunicationProviderProfile) => {
        return profile.id !== providerProfileId;
      },
      this.communicationService.removeProviderProfile(
        organizationID,
        providerProfileId
      )
    );
  }

  @Action(CreateRecipientEndpointAction)
  createRecipientEndpoint(
    ctx: StateContext<CommunicationStateModel>,
    { providerId, spec }: CreateRecipientEndpointAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return addListEntity(
      ctx,
      'orgRecipientEndpoints',
      'id',
      this.communicationService.createRecipientEndpoint(
        organizationID,
        providerId,
        spec
      )
    ).pipe(
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(CreateOrgMemberRecipientEndpointAction)
  createOrgMemberRecipientEndpoint(
    ctx: StateContext<CommunicationStateModel>,
    { providerId, spec }: CreateOrgMemberRecipientEndpointAction
  ) {
    const membership = this.store.selectSnapshot(UserState.membership);

    if (!membership) throw new Error('No membership set');

    return addListEntity(
      ctx,
      'orgMemberRecipientEndpoints',
      'id',
      this.communicationService.createOrgMemberRecipientEndpoint(
        membership.organizationId,
        providerId,
        membership.id,
        spec
      )
    ).pipe(
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(RemoveOrgMemberRecipientEndpointAction)
  removeOrgMemberRecipientEndpoint(
    ctx: StateContext<CommunicationStateModel>,
    { endpointId }: RemoveOrgMemberRecipientEndpointAction
  ) {
    const membership = this.store.selectSnapshot(UserState.membership);

    if (!membership) throw new Error('No membership set');

    return removeListEntity(
      ctx,
      'orgMemberRecipientEndpoints',
      endpointId,
      (endpoint: RecipientEndpoint) => {
        return endpoint.id !== endpointId;
      },
      this.communicationService.removeOrgMemberRecipientEndpoint(
        membership.organizationId,
        membership.id,
        endpointId
      )
    ).pipe(
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(ForwardToDiscordAction)
  forwardToDiscord() {
    const clientId = environment.notificationProviders.discordClientId;

    // https://discordapi.com/permissions.html#378024233040
    const permissions = '378024233040';

    const redirectUri = encodeURIComponent(
      environment.frontendUrl +
        '/settings/communication/providers/callback/discord'
    );

    const discordScopes = encodeURIComponent(['identify', 'bot'].join(' '));

    window.location.replace(
      `https://discord.com/api/oauth2/authorize?client_id=${clientId}&permissions=${permissions}&redirect_uri=${redirectUri}&response_type=code&scope=${discordScopes}`
    );
  }

  @Action(ForwardToSlackAction)
  forwardToSlack() {
    const clientId = environment.notificationProviders.slackClientId;

    const slackScopes = encodeURIComponent(
      ['channels:join', 'channels:manage', 'channels:read', 'chat:write'].join(
        ','
      )
    );

    const redirectUri = encodeURIComponent(
      environment.frontendUrl +
        '/settings/communication/providers/callback/slack'
    );

    window.location.replace(
      `https://slack.com/oauth/v2/authorize?scope=${slackScopes}&client_id=${clientId}&user_scope=&redirect_uri=${redirectUri}`
    );
  }

  @Action(LinkProviderFailedAction)
  linkProviderFailed(
    ctx: StateContext<CommunicationStateModel>,
    { error }: LinkProviderFailedAction
  ) {
    this.toast.showError(new Error(error));
    this.router.navigate(['/settings/communication/providers']);
  }

  @Action(CreateWebPushRecipientEndpointAction)
  async createWebPushRecipientEndpoint(
    ctx: StateContext<CommunicationStateModel>
  ) {
    const supported = await isSupported();
    if (supported) {
      const fcmService = this.injector.get(FCMService);
      try {
        const token = await fcmService.register();

        return ctx
          .dispatch(
            new CreateOrgMemberRecipientEndpointAction('internal-fcm', {
              fcm: {
                token: token,
                identifier: 'web-push',
              },
            })
          )
          .subscribe({
            next: () => {
              this.toast.showInfo(
                'Push notifications enabled!',
                'You will now receive push notifications'
              );
            },
          });
      } catch (error) {
        this.toast.showError(
          new Error('Failed to enable push notifications: ' + error)
        );
      }
    }

    return;
  }
}
