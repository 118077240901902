import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import {
  IncidentSchema,
  IncidentSchemaTaskTemplateLink,
} from 'src/app/models/incident-schema';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Entity, NewEntity, loadEntity } from 'src/app/store/common/entity';
import { UserState } from 'src/app/store/user/user.state';
import {
  IteratableListEntities,
  ListEntities,
  ListEntitiesToIterable,
  NewListEntities,
  addListEntity,
  loadEntities,
  removeListEntity,
  updateListEntity,
} from '../common/list-entity';
import {
  CreateIncidentSchemaTaskTemplateAction,
  DeleteIncientSchemaTaskTemplateLinkAction,
  LoadIncidentSchemaDetails,
  LoadIncidentSchemaTaskTemplateLinks,
  UpdateIncidentSchemaTaskTemplateAction,
} from './incidentSchemaDetails.actions';
import { IncidentSchemasService } from './incidentSchemas.service';

export interface IncidentSchemaDetailsStateModel {
  schema: Entity<IncidentSchema>;
  taskTemplateLinks: ListEntities<IncidentSchemaTaskTemplateLink>;
}

@State<IncidentSchemaDetailsStateModel>({
  name: 'incidentSchemaDetails',
  defaults: {
    schema: NewEntity(),
    taskTemplateLinks: NewListEntities(),
  },
})
@Injectable()
export class IncidentSchemaDetailsState {
  store = inject(Store);
  incidentSchemaService = inject(IncidentSchemasService);
  toast = inject(ToastService);
  translate = inject(TranslateService);

  @Selector()
  static schema(
    state: IncidentSchemaDetailsStateModel
  ): Entity<IncidentSchema> {
    return state.schema;
  }

  @Selector()
  static taskTemplateLinks(
    state: IncidentSchemaDetailsStateModel
  ): IteratableListEntities<IncidentSchemaTaskTemplateLink> {
    return ListEntitiesToIterable(state.taskTemplateLinks);
  }

  static taskTemplateLink(linkId: string) {
    return createSelector(
      [IncidentSchemaDetailsState],
      (state: IncidentSchemaDetailsStateModel) => {
        return state.taskTemplateLinks.entities
          ? state.taskTemplateLinks.entities[linkId]
          : false;
      }
    );
  }

  @Action(LoadIncidentSchemaDetails)
  loadIncidentSchemaDetails(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    { id }: LoadIncidentSchemaDetails
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const observable = this.incidentSchemaService.getIncidentSchema(
      organizationID,
      id
    );

    return loadEntity(ctx, 'schema', observable);
  }

  @Action(LoadIncidentSchemaTaskTemplateLinks)
  loadIncidentSchemaTaskTemplateLinks(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    { id }: LoadIncidentSchemaTaskTemplateLinks
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const observable =
      this.incidentSchemaService.getIncidentSchemaTaskTemplates(
        organizationID,
        id
      );

    return loadEntities(ctx, 'taskTemplateLinks', observable);
  }

  @Action(CreateIncidentSchemaTaskTemplateAction)
  createIncidentSchemaTaskTemplate(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    { taskTemplateSpec }: CreateIncidentSchemaTaskTemplateAction
  ) {
    const schema = ctx.getState().schema;

    if (!schema.data) {
      return;
    }

    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return addListEntity(
      ctx,
      'taskTemplateLinks',
      'linkId',
      this.incidentSchemaService.createIncidentSchemaTaskTemplate(
        organizationID,
        schema.data.currentVersion!.id,
        taskTemplateSpec
      )
    ).pipe(
      tap(() => {
        this.toast.showInfo(
          'Task template created',
          'The task template has been created'
        );
      }),
      catchError(error => {
        this.toast.showError(
          new Error(`Failed to create task template: ${error}`)
        );
        throw error;
      })
    );
  }

  @Action(UpdateIncidentSchemaTaskTemplateAction)
  updateIncidentSchemaTaskTemplate(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    {
      taskTemplateLinkId,
      taskTemplateSpec,
    }: UpdateIncidentSchemaTaskTemplateAction
  ) {
    const schema = ctx.getState().schema;

    if (!schema.data) {
      return;
    }

    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return updateListEntity(
      ctx,
      'taskTemplateLinks',
      taskTemplateLinkId,
      this.incidentSchemaService.updateIncidentSchemaTaskTemplate(
        organizationID,
        taskTemplateLinkId,
        taskTemplateSpec
      )
    ).pipe(
      tap(() => {
        this.toast.showInfo(
          'Task template updated',
          'The task template has been updated'
        );
      }),
      catchError(error => {
        this.toast.showError(
          new Error(`Failed to update task template: ${error}`)
        );
        throw error;
      })
    );
  }

  @Action(DeleteIncientSchemaTaskTemplateLinkAction)
  deleteIncidentSchemaTaskTemplate(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    { linkId }: DeleteIncientSchemaTaskTemplateLinkAction
  ) {
    const schema = ctx.getState().schema;

    if (!schema.data) {
      return;
    }

    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'taskTemplateLinks',
      linkId,
      (link: IncidentSchemaTaskTemplateLink) => {
        return link.linkId !== linkId;
      },
      this.incidentSchemaService.deleteIncidentSchemaTaskTemplate(
        organizationID,
        linkId
      )
    ).pipe(
      tap(() => {
        this.toast.showInfo(
          'Task template deleted',
          'The task template has been deleted'
        );
      }),
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }
}
