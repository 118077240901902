import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { Task, TaskStatus } from 'src/app/models/task';

const UPDATE_TASK_STATUS_MUTATION = gql`
  mutation UpdateTaskStatus(
    $organizationId: ID!
    $taskId: ID!
    $status: TaskStatus!
  ) {
    updateTaskStatus(
      organizationId: $organizationId
      taskId: $taskId
      status: $status
    ) {
      status
    }
  }
`;
const ASSIGN_TASK_MUTATION = gql`
  mutation AssignTask($organizationId: ID!, $taskId: ID!, $assigneeId: ID!) {
    assignTask(
      organizationId: $organizationId
      taskId: $taskId
      assigneeId: $assigneeId
    ) {
      assigneeId
      assignee {
        firstname
        lastname
        avatar
      }
    }
  }
`;

@Injectable()
export class TasksService {
  private apollo = inject(Apollo);

  updateTaskStatus(
    organizationId: string,
    taskId: string,
    status: TaskStatus
  ): Observable<Task> {
    return this.apollo
      .mutate<{ updateTaskStatus: Task }>({
        mutation: UPDATE_TASK_STATUS_MUTATION,
        variables: {
          organizationId: organizationId,
          taskId: taskId,
          status: status,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateTaskStatus;
        })
      );
  }

  assignTask(
    organizationId: string,
    taskId: string,
    assigneeId: string
  ): Observable<Task> {
    return this.apollo
      .mutate<{ assignTask: Task }>({
        mutation: ASSIGN_TASK_MUTATION,
        variables: {
          organizationId: organizationId,
          taskId: taskId,
          assigneeId: assigneeId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.assignTask;
        })
      );
  }
}
